<template>
  <UIInput
    :ref="(el) => inputEl = el"
    v-bind="$attrs"
    v-model="text"
    :disabled="disabled"
  />
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { findHaven, findHotel, findLuchthaven, findStation } from '@taxiboeken-v2/utilities-berekening'

import UIInput from '@/components/UI/Input/Input'

import useGoogleMaps from '@/hooks/useGoogleMaps'

const props = defineProps({
  disabled: Boolean,
  modelValue: [String, Object],
})

const emit = defineEmits(['update:modelValue'])

const inputEl = ref(null)
const autocomplete = ref(null)

const text = ref('')
const adresRef = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const fillInAddress = function (setText, setValue) {
  return function handlerFillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.getPlace()
    if (!place?.address_components) return
    const parts = place.address_components.reduce((result, item) => {
      if (!item.long_name || !item?.types?.length) {
        return result
      }
      item.types.forEach(type => {
        result[type] = item.long_name
      })
      return result
    }, {})
    delete parts.political
    parts.adres = place.formatted_address
    parts.place_id = place.place_id
    parts.lng = place.geometry.location.lng()
    parts.lat = place.geometry.location.lat()
    parts.adresType = 'ADRES'
    if (place?.types.includes('airport') || findLuchthaven(place)) parts.adresType = 'LUCHTHAVEN'
    if (place?.types.includes('station') || findStation(place)) parts.adresType = 'STATION'
    if (place?.types.includes('harbor') || findHaven(place)) parts.adresType = 'HAVEN'
    if (place?.types.includes('hotel') || findHotel(place)) parts.adresType = 'HOTEL'
    setValue(parts)
    setText(place.formatted_address)
  }
}

const setupInput = async () => {
  const google = await useGoogleMaps()
  if (!inputEl.value || autocomplete.value) return
  autocomplete.value = new google.maps.places.Autocomplete(inputEl.value.$el.querySelector('input'), {
    // componentRestrictions: {},
    fields: ['place_id', 'types', 'formatted_address', 'address_components', 'geometry'],
    // types: ['establishment'],
  })
  autocomplete.value.addListener(
    'place_changed',
    fillInAddress(
      v => text.value = v,
      v => adresRef.value = v,
    ),
  )
}

onMounted(async () => {
  await setupInput()
  const adres = adresRef.value || {}
  if (adres.adres || adres.formatted_address) {
    text.value = adres.adres || adres.formatted_address
  }
})
</script>
