import { computed, ref, watch } from 'vue'
import clone from 'just-clone'

import computedBerekening from './computedBerekening'

import useApi from '@/hooks/useApi'

export default ({ componentData, originalForm, form, computedBerekeningRef, typeVervoerComputed }) => {
  const api = useApi()

  const promotieInfo = ref(
    originalForm.value.promotie_code
      ? {
          promotie_code: originalForm.value.promotie_code,
          korting_promotie: originalForm.value.korting_promotie,
        }
      : null,
  )

  const formZonderKorting = computed(() => {
    const formObj = clone(form.value)
    formObj.promotie_code = ''
    formObj.korting_promotie = 0
    return formObj
  })

  const berekeningZonderKortingRef = computedBerekening(formZonderKorting, typeVervoerComputed)

  const handlePromotieInfoNakijken = () => {
    if (promotieInfo?.value) {
      form.value.promotie_code = promotieInfo.value.promotie_code
      form.value.korting_promotie = Math.min(berekeningZonderKortingRef.value?.prijs, Number(promotieInfo.value.korting_promotie))
    }
  }

  watch(promotieInfo, () => handlePromotieInfoNakijken())
  watch(computedBerekeningRef, () => handlePromotieInfoNakijken())

  const handleVoucherToepassen = () => {
    const promotieApiData = {
      code: componentData.value.promotie_code,
      vertrek_datetime: `${form.value.heenreis.datum} ${form.value.heenreis.tijd}`,
      email: form.value.klant?.email || '',
    }
    api.get('PROMOTIE_INFO', promotieApiData).then(({ data }) => {
      if (data?.korting > 0) {
        promotieInfo.value = {
          promotie_code: promotieApiData.code,
          korting_promotie: data.korting,
        }
      }
    })
  }

  const handleClearVoucher = () => {
    componentData.value.promotie_code = null
    form.value.promotie_code = null
    form.value.korting_promotie = 0
    promotieInfo.value = null
  }

  return {
    handleVoucherToepassen,
    handleClearVoucher,
  }
}
