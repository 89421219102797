<template>
  <span class="fixed bg-red-100 border-2 px-3 py-0 font-bold border-red-700 mt-20 right-0 z-floating-rit-alert mr-2 border-r-0 max-w-full">
    <div class="flex gap-2">
      <i class="fas fa-exclamation-circle text-red-900"></i>
      <span>
        <slot></slot>
      </span>
    </div>
  </span>
</template>
