<template>
  <div v-if="show" class="px-4 py-2 text-center bg-red-500 rounded text-white font-bold">
    Prijs verschilt van originele prijs &euro;&nbsp;{{ formatPrice(oudePrijs) }}
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { formatPrice } from '@/functions/formatNumber'

const props = defineProps({
  prijs: [Number, String],
  oudePrijs: [Number, String],
})

const show = computed(() => Number(props.prijs) !== Number(props.oudePrijs))
</script>
