import { isRef } from 'vue'
import clone from 'just-clone'

import generateRitFormData from './generateRitFormData'
import isAdressenVerschillend from './isAdressenVerschillend'
import parseEditForm from './parseEditForm'

export default ({ props, form, originalForm, componentData }) => {
  if (props.modelValue) {
    const value = (isRef(props.modelValue) ? props.modelValue.value : props.modelValue) || {}
    if (Object.keys(value).length) {
      const valueForm = parseEditForm(value)
      form.value = generateRitFormData(valueForm)
      originalForm.value = clone(form.value)
      if (valueForm.id > 0) {
        componentData.value.isNieuw = false
      }
      if (valueForm.korting_promotie > 0 && valueForm.promotie_code) {
        componentData.value.promotie_code = valueForm.promotie_code
      }
      componentData.value.adressenWijzigen = !!componentData.value.isNieuw
      componentData.value.retourVerschillendeAdressen = valueForm.is_retour && isAdressenVerschillend(valueForm.heenreis.adressen, valueForm.retour.adressen)
      if (valueForm.is_luxe_vervoer) {
        componentData.value.typeVervoer = 'luxe'
      } else if (valueForm.is_prive_vervoer) {
        componentData.value.typeVervoer = 'prive'
      }
    }
  }

  if (props.klant) {
    form.value.klant.firstname = props.klant?.firstname || form.value.klant.firstname
    form.value.klant.lastname = props.klant?.lastname || form.value.klant.lastname
    form.value.klant.email = props.klant?.email || form.value.klant.email
    form.value.klant.phone = props.klant?.phone || form.value.klant.phone
    form.value.klant.language = props.klant?.language || form.value.klant.language
  }

  if (props.facturatie) {
    form.value.facturatie.actief = true
    form.value.facturatie.name = props.facturatie?.name || form.value.facturatie.name || ''
    form.value.facturatie.second_name = props.facturatie?.second_name || form.value.facturatie.second_name || ''
    form.value.facturatie.BTW = props.facturatie?.BTW || form.value.facturatie.BTW || ''
    form.value.facturatie.is_particulier = props.facturatie?.is_particulier || form.value.facturatie.is_particulier || false
    form.value.facturatie.adres = props.facturatie?.adres || form.value.facturatie.adres || ''
  }
}
