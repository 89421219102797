import { findLuchthaven } from '@taxiboeken-v2/utilities-berekening'

const luchthavenReplacer = el => findLuchthaven(el) || el
const ZAVENTEM = findLuchthaven({ name: 'Zaventem' })

export default form => {
  if (!form) return form

  form.heenreis.adressen = (form.heenreis.adressen || []).map(luchthavenReplacer)
  form.retour.adressen = !form.is_retour
    ? [
        ZAVENTEM,
        null,
      ]
    : (form.retour.adressen || []).map(luchthavenReplacer)

  return form
}
