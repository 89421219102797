import { computed, watch } from 'vue'

export default ({ form }) => {
  const is_gedeeld_vervoer = computed(() => !form.value.is_prive_vervoer && !form.value.is_luxe_vervoer)
  form.value.afwijking_minuten = is_gedeeld_vervoer.value ? 30 : 0

  watch(is_gedeeld_vervoer, (val, oldval) => {
    if (val == oldval) return
    form.value.afwijking_minuten = val ? 30 : 0
  })

  watch(() => form.value.afwijking_minuten, val => {
    form.value.korting_afwijking = Math.floor((val - 30) / 30) * 5
  })
}
