<template>
  <div>
    <UIInput
      v-model="facturatie.name"
      label="Bedrijfsnaam"
      type="text"
      class="w-full max-w-sm"
      placeholder="Bedrijfsnaam ..."
    />
    <UIInput
      v-model="facturatie.second_name"
      label="Departement/Onderdeel (Tweede lijn naam)"
      type="text"
      class="w-full max-w-sm"
      placeholder="Departement/Onderdeel ..."
    />
    <UIInput
      v-if="!facturatie.is_particulier"
      v-model="facturatie.BTW"
      label="BTW Nummber"
      type="text"
      class="w-full max-w-sm"
      placeholder="BE1234000789"
    />
    <UIInputCheckbox v-model="facturatie.is_particulier" label="Niet BTW Plichtig (Particulier/VZW/...)?" />
    <UIInput
      v-model="facturatie.adres"
      label="Facturatie adres"
      type="text"
      class="w-full max-w-sm"
      placeholder="Facturatie adres ..."
    />
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInput from '@/components/UI/Input/Input'

const props = defineProps({
  modelValue: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['update:modelValue'])
const facturatie = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
</script>
