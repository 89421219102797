<template>
  <div :key="componentData.isNieuw" class="grid 2xl:grid-cols-2 gap-4 max-w-6xl xl:max-w-full mx-auto">
    <PrijsFloating v-if="berekeningPrijs > 0 || (berekeningPrijs == 0 && form.promotie_code)">
      <span>{{ berekeningPrijs }}</span>
    </PrijsFloating>
    <RitAlertFloating v-if="toonPriveVerplichtMelding" class="top-4">
      <p class="block text-sm">
        Normaal verplicht privé vervoer!
      </p>
    </RitAlertFloating>
    <div v-for="deel in ['heenreis', 'retour']" :key="deel">
      <div class="inline-flex w-full justify-between">
        <h2>{{ deel === 'retour' ? 'Retour' : 'Heenreis' }}</h2>
        <div v-if="deel === 'heenreis' && !componentData.adressenWijzigen" class="flex bg-gray-100 rounded-t px-2">
          <UIInputCheckbox v-model="componentData.adressenWijzigen" label="Adressen bewerken?" />
        </div>
        <button v-if="isBewerken && componentData.adressenWijzigen && form.is_retour" class="italic text-red-500 hover:font-bold" @click="handleVerwijderDeel(deel)">
          Verwijder {{ deel }} deel?
        </button>
      </div>

      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-y-2" :class="{ 'bg-gray-100 -mx-2 px-2 -mt-2 pt-2 lg:-m-2 lg:p-2 cursor-not-allowed': !componentData.adressenWijzigen }">
          <UIInputCheckbox
            v-if="deel === 'retour'"
            v-model="form.is_retour"
            :disabled="!componentData.adressenWijzigen"
            label="Retour ook boeken?"
          />
          <UIInputCheckbox
            v-if="deel === 'retour' && form.is_retour"
            v-model="componentData.retourVerschillendeAdressen"
            :disabled="!componentData.adressenWijzigen"
            label="Retour verschillende adressen?"
          />
          <GoogleAddressenLijst
            v-if="deel === 'heenreis' || (form.is_retour && componentData.retourVerschillendeAdressen)"
            :key="form.is_retour"
            v-model="form[deel].adressen"
            :disabled="!componentData.adressenWijzigen"
          />
          <p v-if="form[deel].seconden > 0 && (deel !== 'retour' || (form.is_retour && componentData.retourVerschillendeAdressen))" class="italic font-semibold text-sm text-gray-600 flex flex-col gap-1 pt-2">
            <span class="block">Geschatte reistijd: +- {{ secondsToHMSWritten(form[deel].seconden) }}</span>
            <span class="block">Kilometers: {{ formatDecimalDefault(form[deel].kilometers) }} km</span>
            <span class="block">Google adressen: {{ (form[deel].adressen || [])?.filter(el => !!el?.adres)?.length }}</span>
          </p>
          <GoogleDirections
            v-if="componentData.adressenWijzigen && (deel === 'heenreis' || (form.is_retour && componentData.retourVerschillendeAdressen)) && form[deel].adressen?.filter(el => !!el).length >= 2"
            :id="deel"
            :key="`${deel}-${form[deel].adressen?.filter(el => el?.adres).join('-')}`"
            :adressen="form[deel].adressen"
            @directions="onDirections($event)"
          >
            <RitBerekeningLoadingMask />
          </GoogleDirections>
        </div>

        <div v-if="deel !== 'retour' || form.is_retour" class="flex flex-col gap-y-2">
          <div class="flex flex-col items-start justify-center">
            <InputDatePicker
              v-model:date="form[deel].datum"
              v-model:time="form[deel].tijd"
              label="Datum en tijd"
              class="text-left"
              timepicker
            />
            <small v-if="form.is_last_minute && deel !== 'retour'" class="font-bold italic text-orange-400">Last minute staat aangevinkt</small>
            <RitAlertFeestdag v-if="deel !== 'retour' ? isFeestdagHeen : isFeestdagRetour" />
          </div>
          <div class="grid sm:grid-cols-2 gap-2">
            <div class="flex flex-col gap-2">
              <div class="flex flex-col relative">
                <InputNumber
                  v-model="form[deel].personen"
                  label="Personen"
                  :min="1"
                  :max="50"
                />
              </div>
              <InputNumber
                v-model="form[deel].bagage"
                label="Bagage"
                :min="0"
                :max="50"
              />
              <InputNumber
                v-model="form[deel].handbagage"
                label="Handbagage"
                :min="0"
                :max="50"
              />
            </div>
            <div class="flex flex-col gap-2">
              <InputNumber
                v-model="form[deel].kinderstoelen"
                label="Kinderstoelen"
                :min="0"
                :max="50"
              />
              <InputNumber
                v-model="form[deel].kinderzitjes"
                label="Kinderzitjes"
                :min="0"
                :max="50"
              />
              <InputNumber
                v-model="form[deel].maxicosis"
                label="Maxicosis"
                :min="0"
                :max="50"
              />
            </div>
          </div>
          <UIInput
            v-model="form[deel].vlucht"
            label="Vluchtnummer"
            type="text"
            class="max-w-md"
            placeholder="Vluchtnummer ..."
          />
        </div>
      </div>
    </div>

    <div>
      <h2>Reservatie opties</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-2">
          <UISelectFixed v-model="form.bron" type="RIT_AANVRAAG" :disabled="form.bron === 'WEBSITE'" />
          <div class="flex flex-row gap-2">
            <UISelectFixed v-model="componentData.typeVervoer" type="TYPE_VERVOER" />
            <UISelectFixed
              v-if="componentData.typeVervoer === 'gedeeld'"
              v-model="form.afwijking_minuten"
              type="AFWIJKING_MINUTEN"
              :class="{ 'bg-yellow-200': form.afwijking_minuten > 30 }"
            />
          </div>
          <UISelectFixed v-model="form.betaalmethode" type="BETAALMETHODE" />
          <UIInputCheckbox v-model="form.is_te_ontvangen" label="Te ontvangen door chauffeur?" />

          <div class="flex flex-row gap-2 items-end">
            <UIInput
              v-model="componentData.promotie_code"
              label="Voucher"
              :disabled="!!form.promotie_code"
              class="w-32"
              placeholder="Vouchercode ..."
            />
            <button v-if="componentData.promotie_code && form.promotie_code !== componentData.promotie_code" class="btn success" @click="handleVoucherToepassen">
              Toepassen
            </button>
            <button v-else-if="!!form.promotie_code" class="btn error" @click="handleClickClearVoucher">
              Clear
            </button>
          </div>
          <UISelectFixed
            :key="form.bedrijf_thema"
            v-model="form.berekening"
            class="pb-2 text-xs"
            type="RIT_BEREKENING"
            :options-params="{ bedrijf_thema: form.bedrijf_thema }"
          />

          <UIInputCheckbox v-model="form.is_last_minute" label="Last minute?" />
          <UIInputCheckbox v-if="!isOfferte" v-model="form.is_wakeup_call" label="Wake-up call?" />
          <UIInputCheckbox
            v-if="!isOfferte"
            v-model="form.is_annulatie_verzekering"
            disabled
            label="Annulatie verzekering?"
          />
          <UISelectFixed v-model="form.bedrijf_thema" type="BEDRIJVEN_THEMA" :disabled="form.bron === 'WEBSITE'" />
        </div>
        <div class="flex flex-col gap-4">
          <h3>Factuur gegevens</h3>
          <div class="grid gap-1 scale-90" :class="{ 'grid-cols-2 whitespace-nowrap': form.facturatie.actief }">
            <UIInputCheckbox v-model="form.is_factuur_nodig" label="Factuur nodig?" />
            <UIInputCheckbox v-if="form.is_factuur_nodig" v-model="form.facturatie.actief" label="Factuur gegevens?" />
          </div>
          <div v-show="form.facturatie.actief" class="flex flex-col gap-2">
            <FormFacturatie v-if="form.facturatie.actief" v-model="form.facturatie" class="gap-2" />
            <UISelectFixed
              v-model="form.btw_percentage"
              type="BTW_PERCENT"
              class="w-18"
              label="BTW %"
              :disabled="form.facturatie.actief && form.facturatie.is_particulier"
              placeholder="BTW ..."
            />
          </div>
          <div v-show="form.is_factuur_nodig" class="flex flex-col gap-2">
            <UISelectFixed v-model="form.facturatie_journal_id" type="FINANCIAL_JOURNALS" />
            <UIInput
              v-model="form.facturatie_referentie"
              type="text"
              label="Klant factuur referentie"
              hint="Eigen referentie van klant voor op factuur?"
            />
            <UIInput
              v-model="form.passagier_saldo"
              type="number"
              label="Passagier saldo"
              hint="Bij ritten van Mutas bijvoorbeeld"
            />
            <UIInputCheckbox v-model="form.passagier_saldo_factureren" label="Passagier saldo factureren?" />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2>Persoon gegevens</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="flex flex-col gap-2">
          <h3>Klant gegevens</h3>
          <FormKlant v-model="form.klant" />
          <UISelectFixed v-model="form.persona" type="PERSONA" all />
        </div>
        <div class="flex flex-col gap-2">
          <h3>Reiziger gegevens</h3>
          <UIInputCheckbox v-model="form.reiziger.actief" label="Andere reiziger informatie?" />
          <FormKlant v-if="form.reiziger.actief" v-model="form.reiziger" />
        </div>
      </div>
    </div>

    <div>
      <h2>Opmerking en memo</h2>
      <div class="card shadow lg:grid lg:grid-cols-2 lg:gap-4">
        <UIInput
          v-model="form.opmerking"
          textarea
          label="Opmerking"
          class="w-full"
          placeholder="Opmerking ..."
        />
        <UIInput
          v-model="form.extra_vraag"
          textarea
          label="Extra vraag"
          class="w-full"
          placeholder="Extra vraag ..."
        />
        <UIInput
          v-model="form.planning_optie_heen"
          textarea
          label="Memo heen (intern)"
          class="w-full"
          placeholder="Memo heen (intern) ..."
        />
        <UIInput
          v-if="form.is_retour"
          v-model="form.planning_optie_retour"
          textarea
          label="Memo retour (intern)"
          class="w-full"
          placeholder="Memo retour (intern) ..."
        />
      </div>
    </div>

    <div>
      <h2>Prijs en bevestigen</h2>
      <div class="card shadow">
        <div v-if="berekeningPrijs < 0">
          <small class="block">Rit prijs niet berekenbaar.</small>
          <button v-if="!form.promotie_code && form.korting > 0" class="btn info" @click="form.korting = 0">
            Korting verwijderen
          </button>
        </div>
        <div v-else>
          <div class="lg:grid lg:grid-cols-4 lg:gap-2 mb-2">
            <UIInput
              v-model="form.heenreis.kilometers"
              label="Kilometers heenreis"
              disabled
              class="lg:w-42"
            />
            <UIInput
              v-show="form.is_retour"
              v-model="form.retour.kilometers"
              label="Kilometers retour"
              disabled
              class="lg:w-42"
            />
            <UIInput
              v-model="form.heenreis.tolkosten"
              label="Tolkosten heenreis"
              class="lg:w-42"
              :class="inputHighlightClasses(form.heenreis.tolkosten > 0)"
            />
            <UIInput
              v-show="form.is_retour"
              v-model="form.retour.tolkosten"
              label="Tolkosten retour"
              class="lg:w-42"
              :class="inputHighlightClasses(form.retour.tolkosten > 0)"
              :disabled="!form.is_retour"
            />
          </div>
          <div class="lg:grid lg:grid-cols-4 lg:gap-2">
            <div class="flex flex-col gap-1 flex-1">
              <UIInput
                v-model="form.korting"
                label="Korting"
                class="lg:w-42"
                :class="inputHighlightClasses(form.korting > 0)"
                placeholder="Korting ..."
              />
              <UIInput
                v-if="form.promotie_code && form.korting_promotie > 0"
                v-model="form.korting_promotie"
                label="Korting Promotie"
                class="lg:w-42"
                disabled
                :class="inputHighlightClasses(form.korting_promotie > 0)"
                placeholder="Promotie Korting ..."
              />
              <small v-if="form.promotie_code && form.korting_promotie > 0" class="italic">{{ form.promotie_code }}</small>
            </div>
            <UIInput
              v-model="form.extra_kost"
              label="Extra kost"
              info="'Extra kost' wordt <b>NIET</b> bij in de standaard prijs gerekend"
              class="lg:w-42 flex-1"
              :class="inputHighlightClasses(form.extra_kost > 0)"
              placeholder="Extra kost ..."
            />
            <UIInput
              v-model="form.prijs_verhoging"
              label="Prijs verhoging"
              info="'Prijs verhoging' wordt <b>WEL</b> bij in de standaard prijs gerekend"
              class="lg:w-42 flex-1"
              :class="inputHighlightClasses(form.prijs_verhoging > 0)"
              placeholder="Prijs verhoging ..."
            />
            <UIInput
              label="Prijs"
              disabled
              class="lg:w-42 flex-1"
              :model-value="berekeningPrijs"
            />
          </div>
          <RitBerekeningPrijsVerschilt
            v-if="!componentData.isNieuw"
            class="mt-2"
            :prijs="berekeningPrijs"
            :oude-prijs="originalForm.prijs"
          />
          <div class="flex flex-col sm:flex-row gap-4 mt-4 items-center sm:items-end justify-center">
            <button
              type="submit"
              :disabled="!form.klant.lastname || !form.klant.email"
              class="btn success"
              @click.prevent.stop="onSubmit(true)"
            >
              {{ isOfferte ? 'Opslaan en mailen' : 'Met bevestiging' }} {{ dupliceren ? 'DUPLICEREN' : '' }}
            </button>
            <button
              type="submit"
              :disabled="!form.klant.lastname"
              class="btn error"
              @click.prevent.stop="onSubmit(false)"
            >
              {{ isOfferte ? 'Opslaan zonder mail' : 'Zonder bevestiging' }} {{ dupliceren ? 'DUPLICEREN' : '' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import clone from 'just-clone'

import computedBerekening from './Rit/computedBerekening'
import generateRitFormData from './Rit/generateRitFormData'
import isAdressenVerschillend from './Rit/isAdressenVerschillend'
import loadInPropsData from './Rit/loadInPropsData'
import parseFeestdagAlert from './Rit/parseFeestdagAlert'
import setRetourAdressenFromHeen from './Rit/setRetourAdressenFromHeen'
import syncAfwijking from './Rit/syncAfwijking'
import syncForm from './Rit/syncForm'
import syncLastMinute from './Rit/syncLastMinute'
// import syncTolkosten from './Rit/syncTolkosten'
import syncPriveVerplichtMelding from './Rit/syncPriveVerplichtMelding'
import syncPromotieCode from './Rit/syncPromotieCode'
import syncQueryData from './Rit/syncQueryData'

import FormFacturatie from '@/components/Form/Facturatie.vue'
import FormKlant from '@/components/Form/Klant.vue'
import GoogleAddressenLijst from '@/components/Google/AdressenLijst.vue'
import GoogleDirections from '@/components/Google/Directions.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox.vue'
import InputDatePicker from '@/components/UI/Input/DatePicker'
import UIInput from '@/components/UI/Input/Input.vue'
import InputNumber from '@/components/UI/Input/Number'
import PrijsFloating from '@/components/UI/Labels/PrijsFloating.vue'
import RitAlertFeestdag from '@/components/UI/Labels/RitAlertFeestdag.vue'
import RitAlertFloating from '@/components/UI/Labels/RitAlertFloating.vue'
import RitBerekeningPrijsVerschilt from '@/components/UI/Labels/RitBerekeningPrijsVerschilt.vue'
import RitBerekeningLoadingMask from '@/components/UI/Modal/RitBerekeningLoadingMask'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'

import { secondsToHMSWritten } from '@/functions/formatDate'
import { formatDecimalDefault } from '@/functions/formatNumber'

const props = defineProps({
  modelValue: Object,
  klant: Object,
  facturatie: Object,
  isOfferte: Boolean,
  isBewerken: Boolean,
  dupliceren: Boolean,
})

const emit = defineEmits(['submit', 'update:modelValue', 'datumHeenreisAangepast', 'datumRetourAangepast'])

const originalForm = ref(generateRitFormData(props.modelValue))
const form = ref(generateRitFormData(props.modelValue))

if (props.isOfferte) {
  form.value.heenreis.tijd = '00:00'
  form.value.retour.tijd = '00:00'
}

onMounted(() => {
  watch(form, val => emit('update:modelValue', val), { deep: true })
})

const componentData = ref({
  promotie_code: null,
  isNieuw: true,
  adressenWijzigen: true,
  retourVerschillendeAdressen: false,
  typeVervoer: form.value?.is_luxe_vervoer ? 'luxe' : (form.value?.is_prive_vervoer ? 'prive' : 'gedeeld'),
})

// const reRenderKey = computed(() => [form.value.is_retour].join(''))
const typeVervoerComputed = computed(() => componentData.value.typeVervoer)
const retourVerschillendeAdressenComputed = computed(() => componentData.value.retourVerschillendeAdressen && (
  isAdressenVerschillend(form.value.heenreis.adressen, form.value.retour.adressen)
))

loadInPropsData({ props, form, originalForm, componentData })
syncForm({ form, componentData, typeVervoerComputed, retourVerschillendeAdressenComputed })
syncQueryData({ form })
syncAfwijking({ form })
// syncTolkosten(form)
syncLastMinute({ componentData, form })
const { isFeestdagHeen, isFeestdagRetour } = parseFeestdagAlert({ form })
const toonPriveVerplichtMelding = syncPriveVerplichtMelding({ form })

const computedBerekeningRef = computedBerekening(form, typeVervoerComputed)
const berekeningPrijs = computed(() => {
  if (!componentData.value.isNieuw && !componentData.value.adressenWijzigen && !form.value.touched) {
    return originalForm.value.prijs
  }
  return computedBerekeningRef.value?.prijs || 0
})

onMounted(() => {
  watch(form, () => {
    form.value.touched = true
  }, { deep: true })
  watch(() => form.value.heenreis.datum, date => emit('datumHeenreisAangepast', date))
  watch(() => form.value.retour.datum, date => emit('datumRetourAangepast', date))
})

const inputHighlightClasses = computed(() => (shouldUseClass = true) => ({
  'border-2 border-yellow-300 bg-yellow-300 rounded -mt-0.5': shouldUseClass,
}))

const onDirections = ({ id, distance, duration }) => {
  console.log('onDirections', { id, distance, duration, test: componentData.value })

  if (!componentData.value.isNieuw && !componentData.value.adressenWijzigen) {
    return
  }

  if (form.value[id]) {
    form.value[id].seconden = Math.max(0, duration || 0)
    form.value[id].kilometers = Math.max(0, (distance || 0) / 1000)
    form.value[id].tolkosten = form.value[id].adressen.reduce((total, a) => total + (a?.tolkosten || 0), 0)
  }

  if (id === 'heenreis' && !componentData.value.retourVerschillendeAdressen) {
    setRetourAdressenFromHeen(form)
    onDirections({ id: 'retour', distance, duration })
  }
}

const {
  handleVoucherToepassen,
  handleClearVoucher,
} = syncPromotieCode({ componentData, originalForm, form, computedBerekeningRef, typeVervoerComputed })

const handleClickClearVoucher = () => {
  handleClearVoucher()
  if (!componentData.value.isNieuw) {
    nextTick(() => onSubmit(false))
  }
}

const handleVerwijderDeel = deel => {
  if (deel === 'retour') {
    form.value.is_retour = false
    return
  }
  form.value.heenreis.datum = form.value.retour.datum
  form.value.heenreis.tijd = form.value.retour.tijd
  form.value.heenreis.vlucht = form.value.retour.vlucht
  form.value.heenreis.adressen = [...form.value.retour.adressen]
  form.value.heenreis.datum = form.value.retour.datum
  form.value.heenreis.personen = form.value.retour.personen
  form.value.heenreis.bagage = form.value.retour.bagage
  form.value.heenreis.handbagage = form.value.retour.handbagage
  form.value.heenreis.kinderstoelen = form.value.retour.kinderstoelen
  form.value.heenreis.kinderzitjes = form.value.retour.kinderzitjes
  form.value.heenreis.maxicosis = form.value.retour.maxicosis
  form.value.heenreis.kilometers = form.value.retour.kilometers
  form.value.heenreis.seconden = form.value.retour.seconden
  form.value.heenreis.tolkosten = form.value.retour.tolkosten
  form.value.heenreis.extra_kost = form.value.retour.extra_kost
  nextTick(() => {
    componentData.value.retourVerschillendeAdressen = false
    form.value.is_retour = false
  })
}

const onSubmit = (mail = false) => {
  const submitData = clone({
    ...form.value,
    prijs: berekeningPrijs.value,
    mail: !!mail,
  })

  const a1 = submitData.heenreis.adressen
  const a2 = submitData.retour.adressen
  if (!a1[0] || !a1[a1.length - 1]) return alert('Gelieve adressen heenrit na te kijken.')
  if (submitData.is_retour) {
    if (!a2[0] || !a2[a2.length - 1]) return alert('Gelieve adressen retour na te kijken.')
  }

  if (!retourVerschillendeAdressenComputed.value) {
    setRetourAdressenFromHeen(form)
  }

  emit('submit', submitData)
}
</script>
