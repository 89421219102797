import { watch } from 'vue'

export default form => {
  watch(() => form.value.heenreis.personen, (val, oldval) => {
    // Heenreis bagage
    if (form.value.heenreis.bagage === oldval) form.value.heenreis.bagage = val
    if (form.value.heenreis.handbagage === oldval) form.value.heenreis.handbagage = val
    // Retour pesonen en bagage
    if (!form.value.is_retour) return
    if (form.value.retour.personen === oldval) form.value.retour.personen = val
    if (form.value.retour.bagage === oldval) form.value.retour.bagage = val
    if (form.value.retour.handbagage === oldval) form.value.retour.handbagage = val
  })

  watch(() => form.value.retour.personen, (val, oldval) => {
    // Heenreis bagage
    if (form.value.retour.bagage === oldval) form.value.retour.bagage = val
    if (form.value.retour.handbagage === oldval) form.value.retour.handbagage = val
    // if (form.value.heenreis.personen === oldval) {
    //   form.value.heenreis.personen = val
    //   if (form.value.heenreis.bagage === oldval) form.value.heenreis.bagage = val
    //   if (form.value.heenreis.handbagage === oldval) form.value.heenreis.handbagage = val
    // }
  })
}
