<template>
  <div class="flex flex-row">
    <div>
      <select v-model="selectie" :disabled="disabled" class="block w-full">
        <option v-for="item in LUCHTHAVENS" :key="item.adres" :value="item">
          Luchthaven {{ item.name }}
        </option>
        <option :value="null">
          Zelfgekozen adres invullen
        </option>
      </select>

      <UIGoogleInput
        v-if="!selectie"
        v-model="adres"
        class="mt-1"
        type="text"
        :disabled="disabled"
        placeholder="Adres ..."
      />
    </div>

    <button
      v-if="adres?.place_id"
      :disabled="disabled"
      class="inline-flex justify-center items-center p-2 group"
      @click="adres = null"
    >
      <i class="fas fa-times" :class="{ 'text-dashboard-dark group-hover:text-red-500': !disabled }"></i>
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { findLuchthaven, LUCHTHAVENS } from '@taxiboeken-v2/utilities-berekening'
import clone from 'just-clone'

import UIGoogleInput from '@/components/Google/Input.vue'

const props = defineProps({
  disabled: Boolean,
  modelValue: [String, Object],
})

const emit = defineEmits(['update:modelValue'])

const adres = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

let defaultSelectie = null
if (adres.value) {
  if (findLuchthaven({ place_id: adres.value.place_id }) || findLuchthaven({ adres: adres.value.adres }) || findLuchthaven({ name: adres.value.name })) {
    defaultSelectie = clone(adres.value)
  }
}

const selectie = ref(defaultSelectie)

watch(selectie, val => adres.value = val || null)
</script>
