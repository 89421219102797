import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

import useApi from '@/hooks/useApi'

export default ({ form }) => {
  const api = useApi()
  const route = useRoute()
  const query = computed(() => route.query || {})

  const setFacturatieFromId = id => {
    if (!(id > 0)) return
    api.get('FACTURATIE_DATA', { id }).then(({ data }) => {
      if (!data) return
      Object.entries(data).forEach(([key, value]) => {
        if (typeof form.value.facturatie[key] !== 'undefined') {
          form.value.facturatie[key] = value
        }
      })
      form.value.facturatie.id = id
      form.value.is_factuur_nodig = true
      form.value.facturatie.actief = true
    })
  }

  const setKlantInfoFromId = (id, isReiziger = false) => {
    if (!(id > 0)) return
    api.get('KLANT_INFO_DATA', { id }).then(({ data }) => {
      if (!data) return
      const prop = isReiziger ? 'reiziger' : 'klant'
      if (isReiziger) {
        form.value.reiziger.actief = true
      }
      Object.entries(data).forEach(([key, value]) => {
        if (typeof form.value[prop][key] !== 'undefined') {
          form.value[prop][key] = value
        }
      })
      form.value[prop].id = id
    })
  }

  if (query.value.facturatie) setFacturatieFromId(query.value.facturatie)
  if (query.value.klant_info) setKlantInfoFromId(query.value.klant_info, false)
  if (query.value.reiziger) setKlantInfoFromId(query.value.reiziger, true)

  watch(query, (val, oldval) => {
    if (val.facturatie && val.facturatie !== oldval.facturatie) {
      setFacturatieFromId(val.facturatie)
    }
    if (val.klant_info && val.klant_info !== oldval.klant_info) {
      setKlantInfoFromId(val.klant_info, false)
    }
    if (val.reiziger && val.reiziger !== oldval.reiziger) {
      setKlantInfoFromId(val.reiziger, true)
    }
  })
}
